import { Button, Label } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';

import FormInput from 'jsx/components/core/form/components/FormInputSmall';

const AdminBenchmarkGroupsToolbar = (props) => {
  const {
    onRefresh = noop,
    handleFilterChange = noop,
    periods = [],
    filterControls = noop,
    groups = 0
  } = props;

//   const noneSelected = [{id: '', name: 'None Selected'}];
//   probeFilterControls.period_id.options = noneSelected.concat(periods?.rows?.map(period => ({id: period?.id, name: period?.description})));

  const groupCount = `${groups} Group(s)`;
  return (
    <div className="m-1 p-1 d-flex justify-content-between bg-light rounded">
      <div className="d-flex justify-content-start">
        {/* <Label className="mr-2 text-nowrap" style={{marginTop: 3}}>Filter Period:</Label>
        <FormInput handleChange={handleFilterChange} control={filterControls.period_id} /> */}
        <Label className="mt-1 ml-2 text-nowrap">{groupCount}</Label>
      </div>

      <div className="d-flex justify-content-end">
        <Button onClick={onRefresh} size="sm" color="primary" className="ml-1">
          <Icon name="rotate" />
        </Button>
      </div>
    </div>
  );
};

export default AdminBenchmarkGroupsToolbar;
