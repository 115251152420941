import moment from 'moment';
import { noop } from 'lodash';
import Listview from '../../../../core/form/components/Listview';
import Icon from 'jsx/components/core/icons/Icon';

const AdminWarehouseProbesLsv = ({ rows, onClick = noop, onDelete = noop, onRerun = noop }) => {
  const renderRows = (headers, record) => {
    const extraClass = '';

    const dnc = (record?.property?.dnc ? 'Yes' : 'No');
    const dncClass = (record?.property?.dnc ? 'text-white bg-danger' : 'text-white bg-success');
    const property = `${record?.property?.name} (near ${record?.property?.nearest_town})`;
    const updated = moment(record.updated).local().format('YYYY-MM-DD HH:mm');

    const client_entered = (record?.client_entered?.data?.value ? `${record?.client_entered?.data?.value.toFixed(2)}%` : '-');
    const client_benchmark_values = (record?.client_benchmark_values?.data?.value ? `${record?.client_benchmark_values?.data?.value.toFixed(2)}%` : '-');

    const tableTd = headers.map(({ caption, field, classes, sortColumn, styles }, index) => {
      switch (field) {
        case 'period': {
          return ( <td key={index} className={classes} >{record?.period?.description}</td>);
        }
        case 'advisor': {
          return ( <td key={index} className={classes} >{record?.property?.advisor?.name}</td>);
        }
        case 'roa_client': {
          return ( <td key={index} className={classes} >{client_entered}</td>);
        }
        case 'roa_benchmark': {
          return ( <td key={index} className={classes} >{client_benchmark_values}</td>);
        }
        case 'property': {
          return ( <td key={index} className={classes} >{property}</td>);
        }
        case 'dnc': {
          return ( <td key={index} className={`${classes} ${dncClass}`} style={styles}>{dnc}</td>);
        }
        case 'updated': {
          return ( <td key={index} className={classes} style={styles}>{updated}</td>);
        }
        default:
          return (
            <td key={index} className={classes + extraClass} style={styles}>{record[field]}</td>
          );
      }
    });

    tableTd.push(
      <td key={headers.length + 1} className="text-right">
        <Icon title="Rerun Probe" className="ml-3 text-primary" name="rotate" onClick={(event) => onRerun(event, record)}/>
        <Icon title="Delete Probe" className="ml-3 text-pink" name="trash" onClick={(event) => onDelete(event, record)}/>
      </td>
    );

    return tableTd;
  };

  const iconName = 'leaf';
  const emptyCaption = 'No Probes found in Warehouse for above filter';

  const headers = [
    { caption: 'Analysis Period', field: 'period', classes: 'text-left', styles: {width: '120px'} },
    { caption: 'Property', field: 'property', classes: 'text-left' },
    { caption: 'ROA (Client Entered)', field: 'roa_client', classes: 'text-right', styles: {width: '140px'} },
    { caption: 'ROA (Benchmark)', field: 'roa_benchmark', classes: 'text-right', styles: {width: '140px'} },
    { caption: 'Advisor', field: 'advisor', classes: 'text-left', styles: {width: '120px'} },
    { caption: 'Updated', field: 'updated', classes: 'text-center', styles: {width: '250px'} },
    { caption: 'DNC', field: 'dnc', classes: 'text-right', styles: {width: '50px'} }
  ];

  const tableHeadTh = headers.map(({ caption, classes, styles }, index) => (
    <th key={index} className={classes} style={styles}>
      {caption}
    </th>
  ));

  tableHeadTh.push(
    <th key={headers.length + 1} style={{width: 80}} className="text-right" >Actions</th>
  );

  let tableBodyTr = [];
  let finalRows = rows;
  if (rows?.rows) finalRows = rows.rows;

  const haveRows = finalRows && finalRows.length > 0;
  if (haveRows) {
    tableBodyTr = finalRows.map((row, index) => (
      <tr key={index} onClick={() => onClick(row)} style={{ cursor: 'pointer' }}>
        {renderRows(headers, row)}
      </tr>
    ));
  }

  return (
    <Listview
      rows={finalRows}
      tableHeadTh={tableHeadTh}
      tableBodyTr={tableBodyTr}
      iconName={iconName}
      emptyCaption={emptyCaption}
    />
  );
};

export default AdminWarehouseProbesLsv;
