import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button } from 'reactstrap';
import FormBase from 'jsx/components/core/form/components/FormBase';

import Icon from 'jsx/components/core/icons/Icon';
import Benchmark from '../benchmarking/Benchmark';

class AdminProbeModal extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
        isValid: true,
        isOpen: false,
        data: {},
        id: null,
        isNew: false,
        setModal: null
    };

    this.onClose = this.onClose.bind(this);
  }

  async componentDidUpdate() {
    const { isOpen } = this.state;
    let { id, isNew, title, data, description } = this.state;

    if (isOpen !== this.props.isOpen && isOpen === false) {
      isNew = true;
      ({ description } = this.props);
      data = {};

      id = null;

      if (this.props.data?.id) {
        isNew = false;
        ({ id } = this.props);
        title = `Edit ${modalTitle}`;
      }

      this.setState({
        isOpen: this.props.isOpen,
        id,
        title,
        isNew,
        setModal: this.props.setModal,
        data,
        description,
      });
    }
  }

  onClose(refresh = false) {
    if (refresh && this.props.onClose) this.props.onClose();
    this.state.setModal(false);
    this.setState({
      isOpen: false,
    });
  }

  render() {
    const { isOpen } = this.state;
    const { iconName, probeData } = this.props;

    const title = `Property: ${probeData?.property?.name} (${probeData?.period?.description})`
    return (
      <Modal isOpen={isOpen} className="w90-modal" >
        <ModalHeader className="bg-corporate text-white">
          {iconName && <Icon name={iconName} className="mr-2" />}
          {title}
        </ModalHeader>
        <ModalBody className="p-0">
          <Benchmark
            period_id={probeData?.period?.id}
            property_id={probeData?.property?.id}
            period_description={probeData?.period?.description}
            property_name={probeData?.property?.name}
            hideTitle
            showSubProbesIcon
          />
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button size="sm" color="light" onClick={this.onClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStoreToProps = ({ animal_classes, enterprises }) => ({
  animal_classes,
  enterprises,
});

export default connect(mapStoreToProps)(AdminProbeModal);
