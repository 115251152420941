import { Button, Label } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import { noop } from 'lodash';
import ReactSelect from 'react-select';
import FormInput from 'jsx/components/core/form/components/FormInputSmall';

const AdminWarehouseMetricsToolbar = (props) => {
  const {
    handleFilterChange = noop,
    onRefresh = noop,
    periods,
    metricFilterControls = {},
    rows
  } = props;

  const noneSelected = [{id: '', name: 'None Selected'}];
  metricFilterControls.period_id.options = noneSelected.concat(periods?.rows?.map(period => ({id: period?.id, name: period?.description})));

  const metricCount = `${rows} Metrics`;

  return (
    <div className="m-1 p-1 d-flex justify-content-between bg-light rounded">
      <div className="d-flex justify-content-start">
        <FormInput handleChange={handleFilterChange} control={metricFilterControls.period_id} />
        <ReactSelect
          className="mx-2"
          options={[]}
          placeholder="Filter Eligleble Properties"
          onChange={() => {}}
          value={null}
        />
        <ReactSelect
          className="mx-2"
          options={[]}
          placeholder="Filter KPI Group"
          onChange={() => {}}
          value={null}
        />
        <ReactSelect
          className="mx-2"
          options={[]}
          placeholder="Filter Metric"
          onChange={() => {}}
          value={null}
        />
        <Label className="mt-1 ml-2 text-nowrap">{metricCount}</Label>
      </div>

      <div className="d-flex justify-content-end">

        <Button onClick={onRefresh} size="sm" color="primary" className="ml-1">
          <Icon name="rotate" />
        </Button>
      </div>
    </div>
  );
};

export default AdminWarehouseMetricsToolbar;
