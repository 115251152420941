import React from 'react';
import Select from 'react-select';
import { HalfCircleSpinner } from 'react-epic-spinners';
import { FormGroup, Label, Col } from 'reactstrap';
import { isEmpty, noop } from 'lodash';

const FormInputSelect = ({
  className,
  control,
  handleChange = noop,
  handleKeyDown = noop,
  handleBlur = noop,
  hideCaption = false,
  inputColStyle = {},
  isClearable = false,
  isDisabled = false,
  labelStyle = {},
  isGrouped = false,
  groupLabel = null,
}) => {
  const createOptions = (options) =>
    Array.isArray(options) && !isEmpty(options)
      ? options.map(({ id, name }) => ({ value: id ?? '', label: name }))
      : [];
  const createGroupOptions = (groupOptions) =>
    Array.isArray(groupOptions) && !isEmpty(groupOptions)
      ? groupOptions.map(({ groupLabel: label, options }) => {
          const formattedOptions = options.map(({ id, name }) => ({
            value: id ?? '',
            label: name,
          }));
          return { label, options: formattedOptions };
        })
      : [];

  let { value } = control;
  const options = isGrouped ? createGroupOptions(control.options) : createOptions(control.options);

  value = options?.find((option) => option.value === value) ?? null;

  if (value === null && options.length > 0) [value] = options;

  if (isGrouped && groupLabel) value = { label: groupLabel };

  if (control.disabled) isDisabled = true;

  return (
    <FormGroup row className="mt-0 mb-0">
      {control.caption && !hideCaption && (
        <Label for={control.name} sm={12} className="font-weight-bold" style={labelStyle}>
          {control.caption}
        </Label>
      )}
      <Col sm={12} style={inputColStyle}>
        <Select
          inputId={control.name}
          name={control.name}
          className={`${className ?? ''}${control.warning ? ' input-warning-border' : ''}`}
          isClearable={isClearable}
          isDisabled={isDisabled}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          options={options}
          value={value}
        />
        {control.loading && (
          <HalfCircleSpinner size={20} color="grey" className="mt-1 p-1 ml-n4-half" />
        )}
        {!control.valid && <small className="text-danger">{control.message}</small>}
        {control.valid && control.warning && (
          <small className="text-warning">{control.message}</small>
        )}
        {control.description && <small className="text-success">{control.description}</small>}
      </Col>
    </FormGroup>
  );
};

export default FormInputSelect;
