/* eslint-disable arrow-body-style */
import { connect } from 'react-redux';
import moment from 'moment';
import FormBase from 'jsx/components/core/form/components/FormBase';
import BenchmarksLsv from '../../components/benchmarking/BenchmarksLsv';
import Benchmark from './Benchmark';
import PageTitle from '../../../../core/form/components/PageTitle';
import { fetchWarehouseUserProbes } from '../../actions/warehouse';

class Benchmarks extends FormBase {
  constructor(props) {
    super(props);

    this.state = {
      showLsv: true,
      probe_id: null,
      period_id: null,
      property_id: null,
      period_description: null,
      property_name: null
    };

    this.onClick = this.onClick.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchWarehouseUserProbes());
  }

  onClick(row) {
    const period_todate = moment(row.period.date).format('MMM-YY');
    const period_fromdate = moment(row.period.date).subtract(1, 'year').add(1, 'month').format('MMM-YY');
    const period_description = `${period_fromdate} to ${period_todate}`;
    
    this.setState({probe_id: row.id, property_name: row?.property?.name, period_id: row.period_id, property_id: row.property_id, showLsv: false, period_description});
  }

  goBack() {
    this.setState({showLsv: true});
  }

  render() {
    const { userProbes } = this.props.warehouse;
    const { period_id, property_id, showLsv, period_description, property_name, probe_id } = this.state;
    const title = 'Benchmark Probes';

    return (
      <div>
        {showLsv && (
          <>
            <PageTitle title={title} />
            <BenchmarksLsv
              onClick={this.onClick}
              rows={userProbes}
            />
          </>
        )}

        {!showLsv && (
          <>
            <small><a href="#" onClick={this.goBack}>Back to Probes List</a></small>
            <Benchmark showProbeSelect probe_id={probe_id} period_id={period_id} property_id={property_id} period_description={period_description} property_name={property_name} />
          </>
        )}
      </div>
    );
  };
}

const mapStoreToProps = ({ warehouse }) => ({
  warehouse
});

export default connect(mapStoreToProps)(Benchmarks);
