
import { defaults, allowed } from '../../../../lib/genericReducer';

export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    responseMessage: null,
    probeRows: [],
    probeGroups: [],
    probes: [],
    groups: [],
    userProbes: [],
    metrics: [],
    eligibles: []
  },
  action,
) {
  const types = ['SCHEDULE', 'WAREHOUSE', 'BATCH', 'LOG'];
  if (!allowed(types, action)) return state;

  const updatedState = {
    ...state,
    ...defaults(action),
  };
  
  switch (action.type) {
    case 'FETCH_WAREHOUSE_ELIGIBLE_PROPERTIES_FULFILLED': {
      return {
        ...state,
        eligibles: action.payload,
        fetching: false
      };
    }
  
    case 'FETCH_WAREHOUSE_METRICS_FULFILLED': {
      return {
        ...state,
        metrics: action.payload,
      };
    }

    case 'FETCH_WAREHOUSE_USER_PROBES_FULFILLED': {
      return {
        ...state,
        userProbes: action.payload,
      };
    }
    case 'FETCH_WAREHOUSE_PROBES_FULFILLED': {
      return {
        ...state,
        probes: action.payload,
      };
    }
    case 'FETCH_WAREHOUSE_PROPERTY_FULFILLED': {
      return {
        ...state,
        probeRows: action.payload,
      };
    }
    
    case 'FETCH_WAREHOUSE_GROUPS_FULFILLED': {
      return {
        ...state,
        groups: action.payload,
      };
    }
    case 'FETCH_WAREHOUSE_GROUP_FULFILLED': {
      return {
        ...state,
        probeGroups: action.payload,
      };
    }
    default:
      break;
  }

  return updatedState;
}
