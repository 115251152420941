import React from 'react';
import { BarChart, Bar, YAxis, XAxis, CartesianGrid, Tooltip, ReferenceLine, ResponsiveContainer, ComposedChart } from 'recharts';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';

export const BenchmarkChart = (props) => {
  const {
    row,
    colours,
    isOpen = false,
    onClose,
    setModal,
    chartData,
    chartTitle,
    chartExtra
  } = props;

  const description = `${chartExtra.value} ${chartExtra.metric_name} for ${chartExtra.property_name} property based on ${chartExtra.column_tag} Probes for ${chartExtra.period_description}`;

  const copyToClipboard = () => {
    // Iterate through row arrays and return comman-delimited strings
    const rows = chartData.map(dataRow => {
      const csvRow = Object.keys(dataRow).map(key => dataRow[key]).join(',');
      return csvRow.replace(/.$/,'');
    });

    // Copy to clipboard
    navigator.clipboard.writeText(rows.join('\n'));
  };

  return (
    <Modal isOpen={isOpen} className="w80-modal mt-3" >
      <ModalHeader className="bg-primary text-white border-top border-primary">
        {chartTitle}
      </ModalHeader>
      <ModalBody className="p-0">
        <div className="p-2 bg-lightgray text-center">{description}</div>
        <div style={{ width: '100%', height: 500, padding: 1}}>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              layout="vertical"
              // width={500}
              // height={400}
              data={chartData}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <ReferenceLine x={chartExtra.rawValue} stroke="#cc0000" />
              <ReferenceLine x={0} stroke="#413ea0" />
              <XAxis type="number" dataKey="value" style={{fontSize: 12}} tickFormatter={tick => {return tick.toLocaleString()}} unit={chartExtra?.unit_attributes?.suffix} />
              <YAxis dataKey="name" type="category" scale="band" width={150} style={{fontSize: 12}} interval={0}/>
              <Tooltip formatter={(value) => `${value.toLocaleString()}${chartExtra.unit_attributes.suffix}`} />

              <Bar dataKey="value" barSize={20} fill="#413ea0" />
            </ComposedChart>
          </ResponsiveContainer>
        </div>

      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        <Button size="sm" color="light" onClick={() => setModal(false)}>
          Close
        </Button>

        <Button size="sm" color="warning" onClick={copyToClipboard}>
          Copy to Clipboard
        </Button>
      </ModalFooter>
    </Modal>
  );
};
